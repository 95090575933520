.panel-info > .panel-heading
{
    /*background-color: #5cb460;*/
    background-color: #ebe2e0;
    margin-bottom: 0px;
}
.panel-info > .panel-heading-dark
{
    /*background-color: #5cb460;*/
    background-color: #333333;
    margin-bottom: 0px;
}
.light-title
{
    margin-left: 15px;
    color: whitesmoke;
    /*color : white;*/
}
.silver-header
{
    /*background-color: #5cb460;*/
    background-color: #ebebe0;
    margin-bottom: 0px;
}
.panel-info > .panel-footer
{
    background-color:#3d3d29;
    margin-bottom: 0px;
    margin-top : 0px;
}
.panel-info > .panel-body
{
    margin-bottom: 0px;
    margin-top : 0px;
}
.dark-title
{
    color: black;
    /*color : white;*/
}
.btn{
    white-space: normal;
    margin : 5px;
}

/*https://proto.io/freebies/onoff/*/
.onoffswitch {
    position: relative; width: 90px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #378603; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #EEEEEE; color: #888888;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 6px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 56px;
    border: 2px solid #999999; border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}
/*https://proto.io/freebies/onoff/*/